@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');


:root {
  --primary-color: #6c5ce7;
  --secondary-color: #00cec9;
  --accent-color: #fdcb6e;
  --text-color: #2d3436;
  --background-color: #dfe6e9;
  --card-background: #ffffff;
  --shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Navbar styles */
.navbar {
  background-color: var(--primary-color);
  padding: 15px 0;
  margin-bottom: 30px;
  border-radius: 0 0 20px 20px;
  box-shadow: var(--shadow);
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin: 0 20px;
}

.navbar a,
.toggle-dark-mode {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  transition: var(--transition);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.navbar a::after,
.toggle-dark-mode::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--accent-color);
  transition: var(--transition);
}

.navbar a:hover::after,
.toggle-dark-mode:hover::after {
  width: 100%;
}

.toggle-dark-mode svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.day-box {
  text-align: center;
  margin-bottom: 20px;
  padding: 5px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  box-shadow: var(--shadow);
  max-width: 600px;
  margin: 20px auto;
}

.day-box.dark-mode {
  background-color: #4834d4;
  color: #f1f2f6;
}

.gradient-text {
  background-image: linear-gradient(
          to right,
          #ff909b, #FFDFBA, #ffff72, #BAFFC9, #ffff72, #FFDFBA, #ff909b
  );
  background-size: 200% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 7s linear infinite;
}

@keyframes gradient {
  to {
    background-position: 200% center;
  }
}

.day-box h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

/* Current Lecture styles */
.current-lecture {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--shadow);
  transition: var(--transition);
  background-color: var(--card-background);
  position: relative;
  overflow: hidden;
}

.current-lecture::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.1;
}

.current-lecture h2, .current-lecture h3 {
  margin-top: 0;
  color: var(--primary-color);
  font-weight: 600;
}

.current-lecture.green {
  border-left: 6px solid var(--secondary-color);
}

.current-lecture.red {
  border-left: 6px solid #ff7675;
}

.current-lecture.orange {
  border-left: 6px solid var(--accent-color);
}

.lecture-details {
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(108, 92, 231, 0.1);
  border-radius: 10px;
}

.lecture-details p {
  margin: 10px 0;
}

.lecture-type {
  font-weight: 600;
  color: var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--text-color);
}

.progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 8px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  border-radius: 10px;
  background-color: var(--secondary-color);
  transition: width 1s ease-in-out;
}

.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.1) 25%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.1) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.next-lecture {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(253, 203, 110, 0.1);
  border-radius: 10px;
  border-left: 6px solid var(--accent-color);
}

/* Schedule styles */
.schedule-container h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 2.5em;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.schedule-container h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

.filters {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}

.filters input, .filters select {
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: var(--shadow);
  transition: var(--transition);
}

.filters input:focus, .filters select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.3);
}

.filters input {
  flex-grow: 1;
}

.schedule-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.lecture-card {
  border-radius: 15px;
  padding: 20px;
  transition: var(--transition);
  background-color: var(--card-background);
  box-shadow: var(--shadow);
  position: relative;
  overflow: hidden;
}

.lecture-card::before {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  width: 60px;
  height: 60px;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.2;
  transition: var(--transition);
}

.lecture-card:hover::before {
  transform: scale(2);
}

.lecture-card:hover {
  transform: translateY(-5px) rotate(1deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.lecture-card h2 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0;
  color: var(--primary-color);
  font-weight: 600;
}

.lecture-card .day {
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 300;
}

.day {
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 300;
}

.lecture-card .lecture-details p {
  margin: 8px 0;
}

.status-red {
  border-left: 6px solid #ff7675;
}

.status-green {
  border-left: 6px solid var(--secondary-color);
}

.status-orange {
  border-left: 6px solid var(--accent-color);
}

.status-default {
  border-left: 6px solid var(--primary-color);
}

/* Dark Mode Styles */
body.dark-mode {
  --text-color: #f1f2f6;
  --background-color: #2f3640;
  --card-background: #353b48;
}

body.dark-mode .navbar {
  background-color: #4834d4;
}

body.dark-mode .filters input,
body.dark-mode .filters select {
  background-color: #4b4b4b;
  color: #f1f2f6;
}

body.dark-mode .filters input::placeholder {
  color: #ced6e0;
}

body.dark-mode .lecture-details {
  background-color: rgba(72, 52, 212, 0.2);
}

body.dark-mode .next-lecture {
  background-color: rgba(253, 203, 110, 0.2);
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3 {
  color: #F7EFE5;
}

/* Responsive design */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .filters input,
  .filters select {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }

  .schedule-grid {
    grid-template-columns: 1fr;
  }

  .navbar ul {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }

  .navbar li {
    margin: 10px 0;
  }
}


.tests-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tests-container h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  font-size: 2.5em;
  font-weight: 600;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.tests-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.test-card {
  border-radius: 15px;
  padding: 20px;
  transition: var(--transition);
  background-color: var(--card-background);
  box-shadow: var(--shadow);
  position: relative;
  overflow: hidden;
}

.test-card::before {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  width: 60px;
  height: 60px;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.2;
  transition: var(--transition);
}

.test-card:hover::before {
  transform: scale(2);
}

.test-card:hover {
  transform: translateY(-5px) rotate(1deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.test-card h2 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0;
  color: var(--primary-color);
  font-weight: 600;
}

.status-red {
  border-left: 6px solid #ff7675;
}

.status-green {
  border-left: 6px solid var(--secondary-color);
}

.status-orange {
  border-left: 6px solid var(--accent-color);
}

.upcoming-test-alert {
  background-color: var(--accent-color);
  color: var(--text-color);
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

/* Dark mode styles for tests */
body.dark-mode .test-card {
  background-color: var(--card-background);
}

body.dark-mode .test-card h2 {
  color: var(--text-color);
}

body.dark-mode .upcoming-test-alert {
  /*background-color: #4834d4;*/
  /*color: #f1f2f6;*/
  color: black;
}


.tests-container h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 2.5em;
  position: relative;
  display: inline-block;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
}

.tests-container h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}


.points-tracker {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* ... (previous styles remain the same) ... */
/* Existing styles remain unchanged */

/* PointsTracker styles */
.pt-points-tracker {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.pt-points-tracker h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 2.5em;
  position: relative;
}

.pt-points-tracker h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

.pt-student-card {
  background-color: var(--card-background);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: var(--shadow);
  transition: var(--transition);
}

.pt-student-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.pt-student-card h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.8em;
}

.pt-subject-progress {
  margin-bottom: 20px;
}

.pt-subject-progress h3 {
  color: var(--secondary-color);
  margin-bottom: 10px;
  font-size: 1.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Dark mode styles */
body.dark-mode .pt-subject-progress h3 {
  color: var(--secondary-color);
}


.pt-exam-tag {
  background-color: var(--primary-color);
  color: white;
  font-size: 0.7em;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: normal;
}

body.dark-mode .pt-exam-tag {
  background-color: #4834d4;
}

/* ... (предишните стилове остават непроменени) ... */

.pt-progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 25px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.pt-progress-bar {
  height: 100%;
  display: flex;
  transition: width 0.5s ease-in-out;
}

.pt-progress-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  transition: width 0.5s ease-in-out;
}

.pt-progress-section-домашно {
  background-color: var(--primary-color);
}

.pt-progress-section-присъствие {
  background-color: var(--secondary-color);
}

.pt-progress-section-бонус {
  background-color: var(--accent-color);
}

.pt-total-points {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-color);
  font-weight: bold;
  font-size: 0.9em;
}

/* ... (останалите стилове остават непроменени) ... */


.pt-points-breakdown {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.pt-points-breakdown-item {
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}

.pt-points-breakdown-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.pt-access-code-form {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: var(--card-background);
  border-radius: 15px;
  box-shadow: var(--shadow);
  text-align: center;
}

.pt-access-code-form h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.pt-access-code-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.pt-access-code-form button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: var(--transition);
}

.pt-access-code-form button:hover {
  background-color: var(--secondary-color);
}

.pt-student-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Текстът и полето да са на една линия */
}

.pt-student-filter label {
  white-space: nowrap; /* Текстът да не се пренася */
  margin-right: 10px;
  font-weight: bold;
}

.pt-student-filter select {
  width: 100%; /* Заема цялата ширина */
  max-width: 300px; /* Можеш да зададеш максимална ширина, ако искаш */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}


/* Dark mode styles */
body.dark-mode .pt-student-card {
  background-color: var(--card-background);
}

body.dark-mode .pt-progress-bar-container {
  background-color: #4b4b4b;
}

body.dark-mode .pt-total-points {
  color: white;
}

body.dark-mode .pt-points-breakdown-item {
  background-color: #4b4b4b;
}

body.dark-mode .pt-access-code-form {
  background-color: var(--card-background);
}

body.dark-mode .pt-access-code-form input {
  background-color: #4b4b4b;
  color: var(--text-color);
  border-color: #6c5ce7;
}

.alert {
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.alert-green {
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
}

.alert-yellow {
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
}

.alert-red {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
}

/* Responsive design */
@media (max-width: 768px) {
  .pt-points-tracker {
    padding: 10px;
  }

  .pt-student-card {
    padding: 15px;
  }

  .pt-subject-progress h3 {
    font-size: 1.2em;
  }

  .pt-exam-tag {
    font-size: 0.6em;
  }

  .pt-points-breakdown-item {
    font-size: 0.8em;
  }
}